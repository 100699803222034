<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="onSubmit">
        <el-form :inline="true" label-position="right" :model="form" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="热门商圈名称	">
                <el-autocomplete
                  class="inline-input"
                  valueKey="businessCenterName"
                  v-model="form.hotDataName"
                  @input="handleInput"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button type="primary" icon="el-icon-search" @click="onSubmit">{{ $t('button.search') }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <div class="dataWrapper marginT20">
        <div class="dataItem" v-for="item in hotData" :key="item.name">
          <div class="icon">
            <img class="iconImg" :src="item.icon" alt="" />
          </div>
          <div class="content">
            <p class="name">{{ item.name }}</p>
            <p class="number">{{ item.number }}</p>
          </div>
        </div>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingT10 paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          stripe
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            show-overflow-tooltip
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDuring } from "@/common/js/public.js";
export default {
  components: {},
  data() {
    return {
      form: {
        hotDataName: "",
        hotDataId: "",
      },
      parkNameValue: "",
      loading: false,
      hotareaDetail: "",
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "parkTimeRatio",
          label: "前7日平均泊位利用率",
          width: "",
          formatter: (row) => {
            return (row.parkTimeRatio * 100).toFixed(2) + "%";
          },
        },
        {
          prop: "averageBerthTurnover",
          label: "前7日日均泊位周转次数(次)",
          width: "",
          formatter: (row) => {
            return row.averageBerthTurnover + "次";
          },
        },
        {
          prop: "averageParkTime",
          label: "前7日次均停车时长",
          width: "",
          formatter: (row) => {
            return formatDuring(row.averageParkTime, "seconds");
          },
        },
        {
          prop: "averageExitCount",
          label: "前7日日均停车次数(次)",
          width: "",
          formatter: (row) => {
            return row.averageExitCount + "次";
          },
        },
      ],
      tableData: [],
      hotData: [
        {
          icon: require("./images/hotAreaAnalysis1.png"),
          name: "前7日平均泊位利用率",
          number: "0%",
        },
        {
          icon: require("./images/hotAreaAnalysis2.png"),
          name: "前7日日均泊位周转次数 ",
          number: "0次",
        },
        {
          icon: require("./images/hotAreaAnalysis3.png"),
          name: "前7日次均停车时长",
          number: "0秒",
        },
        {
          icon: require("./images/hotAreaAnalysis4.png"),
          name: "前7日日均停车次数",
          number: "0次",
        },
      ],
    };
  },
  methods: {
    handleInput(v) {
      if (!v) {
        this.form.hotDataId = "";
        this.form.hotDataName = "";
      }
    },
    onSubmit() {
      this.tableData = [];
      if (!this.form.hotDataId) {
        this.form.hotDataName = "";
        this.$alert("请输入并选择商圈");
        return;
      }
      let url = "/acb/2.0/businessCenter/centerStatistics";
      this.$axios
        .get(url, {
          data: {
            businessCenterId: this.form.hotDataId,
          },
        })
        .then((res) => {
          this.hotareaDetail = res.value.parkReportVO;
          this.$set(
            this.hotData[0],
            "number",
            (this.hotareaDetail.parkTimeRatio * 100).toFixed(2) + "%"
          );
          this.$set(this.hotData[1], "number", this.hotareaDetail.averageBerthTurnover + "次");
          this.$set(
            this.hotData[2],
            "number",
            formatDuring(this.hotareaDetail.averageParkTime, "seconds")
          );
          this.$set(this.hotData[3], "number", this.hotareaDetail.averageExitCount + "次");
          this.tableData = res.value.parkReportList;
        });
    },
    querySearch(queryString, cb) {
      this.form.hotDataId = "";
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/businessCenter/getAll";
      this.$axios.get(url).then((res) => {
        var restaurants = res.value;
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.businessCenterName.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      // console.log(item)
      this.form.hotDataName = item.businessCenterName;
      this.form.hotDataId = item.businessCenterId;
    },
    // 默认选中第一个热门商圈
    getALl() {
      let url = "/acb/2.0/businessCenter/getAll";
      this.$axios.get(url).then((res) => {
        this.form.hotDataName = res.value[0].businessCenterName;
        this.form.hotDataId = res.value[0].businessCenterId;
        this.onSubmit();
      });
    },
  },
  mounted() {
    this.getALl();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.dataWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .dataItem {
    display flex
    justify-content flex-start
    align-items center
    width: 24%;
    padding: 20px;
    background: #FFF;
    box-sizing: border-box;
    border-radius: 11px;
    box-shadow: 0px 2px 4px 0px rgba(28,41,90,0.04);
    .icon {
      width 46px;
      height 46px;
      margin-right 20px
      .iconImg {
        width 46px;
        height 46px;
      }
    }
    .name {
      font-size: 14px;
      color: #8687a5;
      margin-bottom 20px
    }
    .number {
      font-size: 22px;
      color: #2f2f51;
    }
  }
}
</style>
